<template>
  <div class="bg-white">
    <app-modal-confirm
      ref="category_delete_confirm"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteCategory"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>
    <validation-observer
      ref="entry"
      v-slot="{ passes, valid, validated }"
    >
      <form class="form-horizontal" v-on:submit.prevent="createOrUpdate">
        <div class="card-body">
          <div v-if="idEditing">
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("common.id") }}</label>
              <div class="col-xl-2 col-lg-3 col-sm-3 col-form-label"> {{ entry.id }}</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label pt-0">{{ $t("reservation.mansion") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-select
                name="apartment_id"
                rules="required"
                :options-data="meta.apartments"
                v-model="entry.apartment_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label pt-0">ルームサービス
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-select
                name="information_id"
                rules="required"
                :options-data="meta.informations"
                v-model="entry.information_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("service_category.name") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9 num-people">
              <app-input
                name="name"
                rules="required"
                :class="['flex-fill']"
                v-model="entry.name"
                type="string:128"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">運営時間</label>
            <div class="col-xl-1 col-lg-1 col-sm-1">
              <app-input
                name="opening_time"
                type="string:5"
                rules="hour_minute"
                :class="['flex-fill']"
                v-model="entry.opening_time"
              />
            </div>
            <label class="col-xl-1 col-lg-1 col-sm-1 col-form-label text-center">~
            </label>
            <div class="col-xl-1 col-lg-1 col-sm-1">
              <app-input
                name="closing_time"
                type="string:5"
                rules="hour_minute"
                :class="['flex-fill']"
                v-model="entry.closing_time"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("service_category.note") }}
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-text
                name="note"
                type="string1024"
                :class="['flex-fill']"
                v-model="entry.note"
              />
            </div>
          </div>
          <div class="card-footer row justify-content-end gr-button">
            <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10"
                    @click="handlerCancelButton">
              {{$t('common.cancel') }}
            </button>
            <button type="button" :disabled="!valid && validated" class="btn btn btn-danger m-r-10" @click="deleteCategoryConfirm" v-if="idEditing">
              {{ $t('common.delete') }}
            </button>
            <button class="btn btn-primary" :disabled="!valid && validated" type="submit">
              {{idEditing ? $t('common.save') : $t('common.create')}}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>

  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {RESERVATION_CONSTANT} from "../../../constants/reservation";
  import {ROUTES} from "@constants";


  export default {
    name: "ServiceCategoryCreateOrEdit",

    data() {
      const id = this.$route.params.id
      return {
        meta: {
          apartments: [],
          informations: [
            {id: null, name:this.$t('common.choose')},
          ],
        },
        entry: {

        },
        idEditing: id,
      }
    },
    watch: {
      'entry.apartment_id': function(value, oldValue) {
        if (value != oldValue && value != '') {
          this.getRoomServiceList(value)
        }
      },
    },
    async created() {
      if (this.idEditing) {
        await this.$request.get(this.ENDPOINT.SERVICE_CATEGORY_EDIT(this.idEditing)).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error'){
              this.__noticeError(this.$t(res.data.error_msg));
            }
            this.redirectDefaultValue();
          }
          this.entry = res.data.data
        })
      }
    },

    mounted: async function () {
      // $(".tooltip").tooltip("hide");
      this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
        this.meta.apartments = res.data.apartments,
        // this.lastApartment = res.data.apartments[0];
        this.meta.apartments.unshift({
          id: "",
          name: "--選択--"
        })
      })
    },
    methods: {
      async createOrUpdate() {
        if (!await this.$refs.entry.validate()) {
          return;
        }
        let res = null;
        let msg = "";

        if (this.idEditing) {
          res = await this.$request.patch(this.ENDPOINT.SERVICE_CATEGORY_EDIT(this.idEditing), this.entry);
          msg = this.$t("common.msg update ok")
        } else {
          res = await this.$request.post(this.ENDPOINT.SERVICE_CATEGORY_CREATE, this.entry);
          msg = this.$t("common.msg create ok")
        }
        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          if (res.status === 'error') {
            this.redirectDefaultValue()
          }
        } else {
          this.__noticeSuccess(msg);
          this.redirectDefaultValue();
        }
      },
      async deleteCategory() {
        const res = await this.$request.delete(this.ENDPOINT.SERVICE_CATEGORY_DELETE(this.idEditing));
        if (!res.hasErrors()) {
          this.__noticeSuccess(this.$t("common.msg delete ok"));
          this.redirectDefaultValue();
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.category_delete_confirm.hide();
          if (res.status === 'error') {
            this.logoutAction()
          }
        }
      },
      getRoomServiceList(apartment_id) {
        if(apartment_id) {
          this.$request.get(this.ENDPOINT.UI_HELPER_INFORMATION(apartment_id)).then(res => {
            let  informations = res.data.informations;
            if(res.data.informations) {
              informations.unshift({id: null, name: this.$t('common.choose')});
              this.meta.informations = informations
            }
          });
        }
      },
      handlerCancelButton() {
        if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.SERVICE_CATEGORY_LIST) {
          this.__redirectBack()
        } else {
          this.redirectDefaultValue()
        }
      },
      deleteCategoryConfirm(){
        this.$refs.category_delete_confirm.show();
      },
      redirectDefaultValue() {
        this.$router.push({
          name: this.ROUTES.ADMIN.CATEGORY_LIST,
          query: {
            'perPage' : '99999',
            'sortBy': 'order_disp_crowded',
          }
        });
      },
    }
  }
</script>

<style>
  @media (min-width: 768px) and (max-width: 1400px) {
    .icon_nga {
      width: 100%;
    }
    .num-people {
      padding-top: 20px;
    }
  }
  .link_name:hover {
    cursor: pointer;
    color: darkblue !important;
  }
</style>
